import React from "react"


const Footer = () => {
  return (
    <>
      <footer>
        <div className='conatiner text-center top'>
         <p>© 2021. All rights reserved by <a href="saileshadhikari.com.np">Sailesh Adhikari</a>.</p>
        </div>
      </footer>
    </>
  )
}

export default Footer
