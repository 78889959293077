const ResumeApi = [
  {
    id: 1,
    category: "education",
    year: "",
    title: "",

  },
  {
    id: 2,
    category: "education",
    year: "Tribhuwan University(2018 - 2022)",
    title: "BE-Computer Engineering",

  },
  {
    id: 3,
    category: "education",
    year: "Tribhuwan University(2018 - 2022)",
    title: "BE-Computer Engineering",

  },
  {
    id: 4,
    category: "education",
    year: "St.Lawrence College (2016 - 2018) ",
    title: "High School",

  },
  {
    id: 5,
    category: "education",
    year: "Tri-Jyoti School (2016) ",
    title: "SLC",

  },
  // {
  //   id: 1,
  //   category: "experience",
  //   year: "KTM labs pvt ltd , Bansbari Kathmandu ",
  //   title: "Front-end developer Internship",

  // },

]

export default ResumeApi
